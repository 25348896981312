
















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  required,
  numeric,
  minLength,
  maxLength,
  minValue,
} from 'vuelidate/lib/validators'
import { namespace } from 'vuex-class'
const PlaceTypesStore = namespace('PlaceTypesStore')
const LocationsStore = namespace('LocationsStore')
const UserStore = namespace('UserStore')

import { PlaceTypesModel } from '@/core/models/PlaceTypesModel'
import { LocationsModel } from '@/core/models/LocationsModel'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'
import BaseTextarea from '@/components/forms/BaseTextarea.vue'
import BaseSelect from '@/components/forms/BaseSelect/BaseSelect.vue'
import BaseSelectNew from '@/components/forms/BaseSelectNew/BaseSelect.vue'
import Field from '@/components/forms/Field.vue'
import { StepOneInterface } from '@/store/modules/room.module'
import { UserModel } from '@/core/models/UserModel'

const validations = {
  name: { required, maxLength: maxLength(250) },
  description: {
    required,
    minLength: minLength(150),
    maxLength: maxLength(3000),
  },
  capacity: { required, numeric, minValue: minValue(1) },
  area: { required, numeric, minValue: minValue(1) },
  placeType: { required, minLength: minLength(1) },
  address: { required },
}

@Component({
  name: 'StepOne',
  components: {
    FormGroup,
    Field,
    BaseButton,
    BaseCheckbox,
    BaseTextarea,
    BaseIcon,
    BaseSelect,
    BaseSelectNew,
  },
  validations,
})
export default class StepOne extends Vue {
  @Prop({ required: true })
  value!: StepOneInterface

  @Prop({ required: true })
  updateFlag!: boolean

  @Prop({ required: true })
  isMobile!: boolean

  name = ''
  placeType: number[] = []
  description = ''
  capacity: number | null = null
  area: string | null = null
  address: number | null = null
  active = false
  showSchedule = false
  isOpenAddAddressForm = false

  @Watch('value')
  onValueChanged(val: StepOneInterface): void {
    this.updateModel(val)
  }

  @Watch('updateFlag')
  onUpdateFlagChanged(): void {
    this.getLocations()
    this.getPlaceTypes()
  }

  get nameErrorText(): string | null {
    const name = (this.$v as any)?.name
    const isDirty = name.$dirty

    if (isDirty && !name?.required) {
      return 'Поле не заполнено'
    } else if (isDirty && !name?.maxLength) {
      return 'Максимальная длина описания - 250 символов'
    }

    return null
  }

  get descriptionErrorText(): string | null {
    const description = (this.$v as any)?.description
    const isDirty = description.$dirty

    if (isDirty && !description?.required) {
      return 'Поле не заполнено'
    } else if (isDirty && !description?.minLength) {
      return 'Минимальная длина описания - 150 символов'
    } else if (isDirty && !description?.maxLength) {
      return 'Максимальная длина описания - 3000 символов'
    }

    return null
  }

  @UserStore.Getter
  public currentUser!: UserModel

  @PlaceTypesStore.Getter
  public placeTypesList!: PlaceTypesModel

  @PlaceTypesStore.Action
  private getPlaceTypes!: () => Promise<PlaceTypesModel>

  @LocationsStore.Getter
  public locationList!: LocationsModel[]

  @LocationsStore.Action
  private getLocations!: () => Promise<LocationsModel>

  get addLocationAvailable(): boolean {
    if (
      !this.currentUser?.billing_plan?.current &&
      !this.currentUser?.billing_plan?.next
    ) {
      return false
    }

    const maxLocations = this.currentUser?.billing_plan?.current
      ? this.currentUser?.billing_plan?.current?.max_locations
      : this.currentUser?.billing_plan?.next?.max_locations
    return (
      (maxLocations !== undefined && maxLocations == 0) ||
      (maxLocations !== undefined &&
        maxLocations > 0 &&
        this.locationList?.length < maxLocations)
    )
  }

  public checkValidity(): boolean {
    this.$v.$touch()

    return !this.$v.$anyError
  }

  public resetValidity(): void {
    this.$v.$reset()
  }

  public updateModel(data: StepOneInterface): void {
    this.name = data?.name ?? ''
    this.placeType = data?.placeType ?? []
    this.description = data?.description ?? ''
    this.capacity = data?.capacity ?? null
    this.area = data?.area ?? null
    this.address = data?.address ?? null
    this.active = data?.active ?? false
    this.showSchedule = data?.showSchedule ?? false
  }

  public update(): void {
    this.$emit('input', {
      name: this.name,
      placeType: this.placeType,
      description: this.description,
      capacity: this.capacity,
      area: this.area,
      address: this.address,
      active: this.active,
      showSchedule: this.showSchedule,
    })
  }

  public created(): void {
    this.getLocations()
    this.getPlaceTypes()
    this.updateModel(this.value)
  }

  public mounted(): void {
    this.getLocations()
    this.getPlaceTypes()
    this.updateModel(this.value)
  }

  public activated(): void {
    this.getLocations()
  }

  public onClickAddAddress(): void {
    this.$emit('add-address')
    // this.isOpenAddAddressForm = true
  }
}
